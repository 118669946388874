import React from 'react';

import { Box, xcss } from '@atlaskit/primitives';

import { useLegacySearchTheme } from '@atlassian/navigation-system/experimental/use-legacy-search-theme';
import { ProductSearchInputSkeletonInteractiveSSR } from '@atlassian/product-search-dialog/skeleton';

import { fg } from '@confluence/feature-gating';
import {
	NavigationSearchDialog,
	QuickFind,
	useQuickFindEnabled,
} from '@confluence/navigation-search-dialog';
import { CONTEXT_PATH } from '@confluence/named-routes/src';
import { useRenderServerPlaceholder } from '@confluence/ssr-utilities';

export const SearchBar = () => {
	const theme = useLegacySearchTheme();
	const [isQuickFindEnabled] = useQuickFindEnabled();
	const renderServerPlaceholder = useRenderServerPlaceholder();

	const renderSearchBar = () => {
		return renderServerPlaceholder ? (
			<ProductSearchInputSkeletonInteractiveSSR
				placeholder="Search"
				advancedSearchUrl={`${CONTEXT_PATH}/search`}
				shouldFillContainer
			/>
		) : (
			<NavigationSearchDialog shouldFillContainer theme={theme} />
		);
	};

	return (
		<Box xcss={[searchBarWrapper, isQuickFindEnabled && flexStyles]}>
			{isQuickFindEnabled && fg('quick_find_refactor') ? <QuickFind /> : renderSearchBar()}
		</Box>
	);
};

const searchBarWrapper = xcss({
	position: 'relative',
	width: '100%',
	maxWidth: '780px',
});

const flexStyles = xcss({
	display: 'flex',
	alignItems: 'center',
});

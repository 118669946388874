import React, { useCallback } from 'react';

import { QuickFindSkeleton as QuickFindSkeletonInternal } from '@atlassian/search-page/quick-find-skeleton';

import { fg } from '@confluence/feature-gating';
import { LoadableAfterPaint } from '@confluence/loadable';
import { useRouteActions } from '@confluence/route-manager';
import { CONTEXT_PATH } from '@confluence/named-routes';

export const QuickFindSkeleton = () => {
	const { push } = useRouteActions();
	const onEnter = useCallback(
		(e: React.KeyboardEvent) => {
			const input = e.target as HTMLInputElement;
			input.blur();
			push(`${CONTEXT_PATH}/search/?text=${input.value}`);
		},
		[push],
	);

	return <QuickFindSkeletonInternal onEnter={onEnter} isNav4Enabled={fg('confluence_nav_4')} />;
};

export const QuickFindAsync = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-QuickFind" */ './QuickFind')).QuickFind,
	loading: () => <QuickFindSkeleton />,
});

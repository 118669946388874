import React from 'react';

import {
	ConversationAssistantNavBarButton,
	RovoEntitlementSetter,
} from '@confluence/conversation-assistant';
import { AccessStatus, useSessionData } from '@confluence/session-data';

export const RovoButton = () => {
	const { isLoggedIn, isRovoEnabled: isRovoEnabledSessionData, accessStatus } = useSessionData();

	const isRovoEnabled =
		isRovoEnabledSessionData &&
		isLoggedIn &&
		accessStatus !== AccessStatus.EXTERNAL_COLLABORATOR_ACCESS;

	return (
		<>
			{isRovoEnabled && <ConversationAssistantNavBarButton />}
			<RovoEntitlementSetter isRovoEnabled={isRovoEnabled} />
		</>
	);
};

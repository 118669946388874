/* eslint-disable react/no-children-prop */
import type { FC } from 'react';
import React, { memo, useState, useContext, useMemo } from 'react';
import { useIntl } from 'react-intl-next';
// We have deprecated emotion. Please use compiled instead
// eslint-disable-next-line no-restricted-imports, @atlaskit/ui-styling-standard/no-global-styles, @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { Global } from '@emotion/core';
// We have deprecated unstated. Please use react-sweet-state instead
// eslint-disable-next-line no-restricted-imports
import { Subscribe } from 'unstated';

import { SpotlightManager } from '@atlaskit/onboarding';
import { Box, xcss } from '@atlaskit/primitives';

import { Panel } from '@atlassian/navigation-system/layout/panel';
import { Banner } from '@atlassian/navigation-system/layout/banner';
import { Main } from '@atlassian/navigation-system/layout/main';
import { Root } from '@atlassian/navigation-system/layout/root';

import { EditContentButtonPreloaderContext } from '@confluence/edit-button';
import { Attribution, ErrorBoundary } from '@confluence/error-boundary';
import { PerformanceStart, PERFORMANCE_SUBJECT_navigation } from '@confluence/performance';
import { InProductHelpProvider } from '@confluence/in-product-help';
import { RightSidebarContextProvider } from '@confluence/page-layout-context';
import { PageTreeStateUpdater } from '@confluence/page-tree-refresh-state-container';
import { BannerContainer } from '@confluence/banner-container';
import { BannerStateContainer } from '@confluence/banners';
import { useSpaceGettingStartedTour } from '@confluence/experiment-space-tour';
import {
	SHOW_SWITCHER_SPOTLIGHT_ONLY,
	JSW_XFLOW_SWITCHER_TOUR_TASK,
} from '@confluence/onboarding-helpers/entry-points/constants/onboarding-state-constants';
import { useQuickstartEligible, useQuickstartState } from '@confluence/onboarding-quickstart';
import { NotesContextProvider } from '@confluence/notes-util/entry-points/NotesContext';
import { FixedBreadcrumbsNavForPageLayoutLoader } from '@confluence/breadcrumbs';
import {
	CUSTOM_CONTENTS_ON_PAGE_LIST,
	SPACE_PAGES,
	SPACE_BLOGS,
	SPACE_CALENDARS,
	DATABASE_CUSTOM_OVERVIEW,
	SPACE_QUESTIONS,
} from '@confluence/named-routes';
import { RoutesContext } from '@confluence/route-manager/entry-points/RoutesContext';
import type { Route } from '@confluence/route';
import { perfMark, WATERFALL_INITIAL_MEASURES } from '@confluence/action-measures';
import { GlobalComponentsLoader } from '@confluence/global-components';
import { useStopNav4Spotlight } from '@confluence/nav4-onboarding';

import { NavigationExperienceWrapper } from './NavigationExperienceWrapper';
import { SideNavigation } from './SideNavigation';
import { useScrollTree } from './useScrollTree';
import { PanelLoader } from './PanelLoader';
import { i18n } from './messages';
import type { PageLayoutProps } from './PageLayoutTypes';
import {
	styleCodeBlockFix,
	styleNoPrintNav,
	fontFamilyCLSFix,
	ContentRightSidePanel,
	LayoutMainWrapper,
	moreActionDropdownCLSFix,
	StyleFixSSRContentForNewLayoutWithRightSidebar,
} from './presentationComponents';
import { useSideNavInitialState } from './useSideNavInitialState';
import { TopBar } from './TopBar';

const TOP_NAV_HEIGHT = 48;

const isRoute = (pathname: string | undefined, route: Route) =>
	Boolean(route.match(pathname || ''));

const routesToShowPageLayoutBreadcrumbNav = [
	CUSTOM_CONTENTS_ON_PAGE_LIST,
	SPACE_PAGES,
	SPACE_BLOGS,
	SPACE_CALENDARS,
	DATABASE_CUSTOM_OVERVIEW,
	SPACE_QUESTIONS,
];

export const PageLayout: FC<PageLayoutProps> = memo(
	({ children, view, standalone, isSpaceSettingsScreen, editorPreloader }) => {
		return (
			<ErrorBoundary
				attribution={Attribution.BACKBONE}
				attributes={{
					errorBoundaryId: 'PageLayout-outer',
				}}
			>
				<PageLayoutInner
					children={children}
					view={view}
					standalone={standalone}
					isSpaceSettingsScreen={isSpaceSettingsScreen}
					editorPreloader={editorPreloader}
				/>
			</ErrorBoundary>
		);
	},
);

export const PageLayoutInner = ({
	children,
	view,
	standalone,
	isSpaceSettingsScreen,
	editorPreloader,
}) => {
	const { pageTreeUpdater } = useScrollTree({ view });

	const { isLoading, isError, isPanelOpen, isDismissed, openComponentId } = useQuickstartState();
	const { isQuickstartEligible } = useQuickstartEligible();

	const jswSpotlightOpen =
		openComponentId === SHOW_SWITCHER_SPOTLIGHT_ONLY ||
		openComponentId === JSW_XFLOW_SWITCHER_TOUR_TASK;

	// By default, the blanket should not be tinted, only tinted if onboarding jsw switcher spotlights are active
	const spotlightTriggeredByJswQS = isPanelOpen && !isDismissed && jswSpotlightOpen;

	const isQuickStartOpen =
		isPanelOpen && !isDismissed && !isLoading && !isError && isQuickstartEligible();

	const intl = useIntl();
	const { location } = useContext(RoutesContext);

	// show breadcrumb nav for routes listed in routesToShowPageLayoutBreadcrumbNav
	const showBreadcrumbNavHeader = routesToShowPageLayoutBreadcrumbNav.some((route) =>
		isRoute(location?.pathname, route),
	);

	const SideNavSkiplinkText = intl.formatMessage(i18n.SideNav);

	const EditContentButtonPreloaderContextValue = useMemo(
		() => editorPreloader || (() => {}),
		[editorPreloader],
	);

	const [activeNav4Spotlight, setActiveNav4Spotlight] = useState<number | null>(null);
	const stopNav4Spotlight = useStopNav4Spotlight(setActiveNav4Spotlight);
	const [{ isSpaceGettingStartedTourInProgress }] = useSpaceGettingStartedTour();
	const { initialCollapsedState } = useSideNavInitialState();

	const handleBlanketClicked = () => {
		if (activeNav4Spotlight !== null) {
			stopNav4Spotlight();
		}
	};
	return (
		<ErrorBoundary
			attribution={Attribution.BACKBONE}
			attributes={{
				errorBoundaryId: 'PageLayoutInner',
			}}
		>
			<NavigationExperienceWrapper view={view} navVersion="4">
				<PerformanceStart subject={PERFORMANCE_SUBJECT_navigation} subjectId="NavigationLoading" />
				<EditContentButtonPreloaderContext.Provider value={EditContentButtonPreloaderContextValue}>
					<Subscribe to={[BannerStateContainer]}>
						{(bannerState: BannerStateContainer) => {
							const bannerHeight = bannerState.getTotalHeight(false);

							return (
								<SpotlightManager
									blanketIsTinted={
										isSpaceGettingStartedTourInProgress ||
										spotlightTriggeredByJswQS ||
										activeNav4Spotlight !== null
									}
									onBlanketClicked={handleBlanketClicked}
								>
									{standalone ? (
										<Global
											data-testid="global-styles-SSR"
											styles={[
												// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
												StyleFixSSRContentForNewLayoutWithRightSidebar(
													// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
													bannerHeight + TOP_NAV_HEIGHT,
												),
												// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
												styleCodeBlockFix,
												// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
												fontFamilyCLSFix,
												// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
												styleNoPrintNav,
												// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
												moreActionDropdownCLSFix,
											]}
										/>
									) : (
										<Global
											data-testid="global-styles"
											styles={[
												// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
												styleCodeBlockFix,
												// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
												fontFamilyCLSFix,
												// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
												styleNoPrintNav,
												// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
												moreActionDropdownCLSFix,
											]}
										/>
									)}
									<RightSidebarContextProvider>
										<NotesContextProvider>
											<InProductHelpProvider>
												<Root UNSAFE_dangerouslyHoistSlotSizes>
													{/*<ZIndexWrapper zIndex={14} showQuickStart>  This may break quickstart, if it does we can add data-layout-slot to this to prevent all the top nav elements from being hidden*/}
													<Banner UNSAFE_height={bannerHeight} id="AkBanner">
														<BannerContainer />
													</Banner>
													{perfMark(`${WATERFALL_INITIAL_MEASURES.HTML_TOP_NAV}.start`)}
													<TopBar defaultCollapsed={initialCollapsedState === 'collapsed'} />
													{perfMark(`${WATERFALL_INITIAL_MEASURES.HTML_TOP_NAV}.end`)}
													{perfMark(`${WATERFALL_INITIAL_MEASURES.HTML_SIDE_NAV}.start`)}
													<SideNavigation
														view={view}
														skiplinkText={SideNavSkiplinkText}
														isSpaceSettingsScreen={isSpaceSettingsScreen}
														activeNav4Spotlight={activeNav4Spotlight}
														setActiveNav4Spotlight={setActiveNav4Spotlight}
													/>
													{perfMark(`${WATERFALL_INITIAL_MEASURES.HTML_SIDE_NAV}.end`)}
													{perfMark(`${WATERFALL_INITIAL_MEASURES.HTML_MAIN}.start`)}
													<Main testId="grid-main-container" id="AkMainContent" isFixed>
														<LayoutMainWrapper
															id="layout-main-wrapper"
															showBreadcrumbNavHeader={showBreadcrumbNavHeader}
														>
															{showBreadcrumbNavHeader && (
																<FixedBreadcrumbsNavForPageLayoutLoader />
															)}

															{children && !standalone ? (
																<ErrorBoundary attribution={Attribution.DISCO}>
																	{showBreadcrumbNavHeader ? (
																		<Box xcss={breadcrumbsOffsetStyles}>{children}</Box>
																	) : (
																		children
																	)}
																</ErrorBoundary>
															) : null}
															{perfMark(`${WATERFALL_INITIAL_MEASURES.HTML_MAIN}.end`)}
														</LayoutMainWrapper>
													</Main>
													{isQuickStartOpen && (
														<Panel>
															<ContentRightSidePanel id="content-right-side-panel" />
														</Panel>
													)}
													<PanelLoader toggleInProductHelp={() => {}} />
													<Subscribe to={[PageTreeStateUpdater]}>
														{(pageTreeStateUpdatesContainer: PageTreeStateUpdater) => {
															pageTreeUpdater.current = pageTreeStateUpdatesContainer;
															return null;
														}}
													</Subscribe>
												</Root>
												<GlobalComponentsLoader />
											</InProductHelpProvider>
										</NotesContextProvider>
									</RightSidebarContextProvider>
								</SpotlightManager>
							);
						}}
					</Subscribe>
				</EditContentButtonPreloaderContext.Provider>
			</NavigationExperienceWrapper>
		</ErrorBoundary>
	);
};

const breadcrumbsOffsetStyles = xcss({
	// @ts-ignore This specific value is necessary to ensure the breadcrumbs are offset correctly
	marginTop: '60px',
});
